import {useState} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import "./App.css";
import {useLocation, Route, Routes} from "react-router-dom";
import routes from "../../config/routes";
import {
    getCompany,
    getIsAuthenticated,
    getTokenUser,
    setIsAuthenticated,
    setRefreshTokenExpirationTimestamp, setTokenUser,
} from "../../utils/tokenUtils";
import Login from "../auth/Login";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "../../themes";
import {CssBaseline} from "@mui/material";
import {DashboardNavbar} from "../../components/dashboard-navbar";
import {DashboardSidebar} from "../../components/dashboard-sidebar";
import {styled} from "@mui/material/styles";
import axios from "axios";

const DashboardLayoutRoot = styled("div")(({theme}) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
        paddingLeft: 280,
    },
}));

function App(): JSX.Element {
    const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();


    // Flaga, żeby uniknąć zapętlenia przy refreshu tokena
    let isRefreshing = false;
    let refreshSubscribers: any[] = [];

    // Funkcja do obsługi ponownego wysłania requestów po odświeżeniu tokena
    const subscribeTokenRefresh = (cb: (token: string) => void): void => {
        refreshSubscribers.push(cb);
    };

    const onRefreshed = (token: string): void => {
        refreshSubscribers.forEach((cb) => cb(token));
        refreshSubscribers = [];
    };

    // Dodajemy interceptor do Axiosa (sprawdzamy odpowiedzi)
    axios.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // Jeśli dostaniemy 401 (unauthorized), próbujemy odświeżyć token
            if (error.response.status === 401 && !originalRequest._retry) {
                if (isRefreshing) {
                    return new Promise((resolve) => {
                        subscribeTokenRefresh((token) => {
                            originalRequest.headers["Authorization"] = `Bearer ${token}`;
                            resolve(axios(originalRequest));
                        });
                    });
                }

                originalRequest._retry = true;
                isRefreshing = true;
                const currentTokenUser = getTokenUser();
                const currentCompany = getCompany();

                try {
                    const {data} = await axios.post(
                        "/auth/refresh",
                        {email: currentTokenUser?.email, company: currentCompany?.id},
                        {withCredentials: true}
                    );

                    const newAccessToken = data.accessToken;

                    setTokenUser(data.user);
                    setIsAuthenticated(true)
                    setRefreshTokenExpirationTimestamp(data.refreshTokenExpirationTimestamp)

                    axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

                    onRefreshed(newAccessToken);

                    return axios(originalRequest);
                } catch (refreshError) {
                    console.error("Błąd odświeżania tokena", refreshError);
                    return Promise.reject(refreshError);
                } finally {
                    isRefreshing = false;
                }
            }

            return Promise.reject(error);
        }
    );

    if (!getIsAuthenticated() && !getTokenUser()) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexGrow: 1,
                        minHeight: "100%",
                        height: "100vh",
                    }}
                    component="main"
                >
                    <Login/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <DashboardLayoutRoot>
                <Box
                    sx={{
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column",
                        width: "100%",
                        mx: "auto",
                        margin: 3,
                    }}
                    component="main"
                >
                    <Routes location={location}>
                        {routes.map(({path, container: Xxx}, index) => (
                            <Route key={index} path={path} element={<Xxx/>}></Route>
                        ))}
                    </Routes>
                    <Backdrop
                        sx={{color: "#fff", zIndex: (themes) => themes.zIndex.drawer + 1}}
                        open={loading}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </Box>
            </DashboardLayoutRoot>
            <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)}/>
            <DashboardSidebar
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
            />
        </ThemeProvider>
    );
}

export default App;
